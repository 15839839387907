var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "ml-2"
  }, [_vm.isSorted ? _c('div', {
    staticClass: "fa",
    class: {
      'fa-sort-up': _vm.direction > 0,
      'fa-sort-down': _vm.direction < 0
    }
  }) : _c('div', {
    staticClass: "fa fa-sort"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }